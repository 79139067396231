import React, { ChangeEventHandler, ReactNode } from "react";
import {
  TableRow,
  Table,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableFooter,
  TableBody,
} from "@mui/material";

import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface TableProps {
  children?: ReactNode;
  content: any[];
  needPagination: boolean;
  availablePageSize: number[];
  columnList: string[];
  columnAlign: "center" | "left" | "right";
  page: number;
  pageSize: number;
  totalNum: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  onRowsPerPageChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  tableSx?: SxProps<Theme>;
}

const MyTable = (props: TableProps) => {
  const {
    children,
    needPagination,
    availablePageSize,
    columnList,
    columnAlign,
    page,
    pageSize,
    totalNum,
    onPageChange,
    onRowsPerPageChange,
    tableSx,
  } = props;

  const columsStyle: SxProps<Theme> = {
    fontSize: "16px",

    "@media (max-width: 991px)": {
      fontSize: "14px",
      textWrap: "nowrap",
      whiteSpace: "nowrap",
    },
  };

  return (
    <TableContainer component={Paper} sx={tableSx}>
      <Table>
        <TableHead>
          <TableRow>
            {columnList.map((val, index) => {
              if (val === "") {
                return <TableCell key={index} />;
              }
              return (
                <TableCell key={index} align={columnAlign} sx={columsStyle}>
                  {val}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
        {/* Conditional rendering of pagination based on data presence */}
        {totalNum > 0 && (
          <TableFooter>
            <TableRow></TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default MyTable;
