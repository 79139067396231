import { MouseEvent } from "react";
import { useTheme } from "@mui/material/styles";
import { TablePaginationActionsProps } from "../../../types/pagination"; // Adjust the path as needed

export const handleFirstPageButtonClick = (
  event: MouseEvent<HTMLButtonElement>,
  onPageChange: TablePaginationActionsProps["onPageChange"]
) => {
  onPageChange(event, 0);
};

export const handleBackButtonClick = (
  event: MouseEvent<HTMLButtonElement>,
  page: number,
  onPageChange: TablePaginationActionsProps["onPageChange"]
) => {
  onPageChange(event, page - 1);
};

export const handleNextButtonClick = (
  event: MouseEvent<HTMLButtonElement>,
  page: number,
  onPageChange: TablePaginationActionsProps["onPageChange"]
) => {
  onPageChange(event, page + 1);
};

export const handleLastPageButtonClick = (
  event: MouseEvent<HTMLButtonElement>,
  count: number,
  rowsPerPage: number,
  onPageChange: TablePaginationActionsProps["onPageChange"]
) => {
  onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
};

export const usePaginationTheme = () => {
  return useTheme();
};
